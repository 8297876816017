<template>
        <b-container>
            <b-form @submit="onSubmit">
                <b-form-row class="mb-3" v-if="form.partner">
                    <b-col>
                        <b-input-group size="sm">
                        <b-input-group-prepend is-text>
                            Parceiro:
                        </b-input-group-prepend>
                        <b-form-select v-on:change="selQuotaPartner" v-model="form.id_quotapartner" :options="selects.partners" size="sm" />
                        </b-input-group>
                    </b-col>
                </b-form-row>
                <b-form-row v-if="!form.partner">
                    <b-col>
                        <b-form-group id="ningroup"
                                        label-for="cpf"
                                        description="">
                            <b-input-group>
                                <b-form-input id="cpf"
                                            type="text"
                                            name="cpf"
                                            v-mask="['###.###.###-##']"
                                            maxlength="14"
                                            v-model="form.cpf"
                                            v-validate
                                            :class="{'input': true, 'is-danger': errors.has('cpf') }"
                                            placeholder="CPF">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-btn variant="info" @click="get(true, 'cpf')">Buscar</b-btn>
                                </b-input-group-append>
                            </b-input-group>
                            <i v-show="errors.has('cpf')" class="fa fa-warning"></i>
                            <span v-show="errors.has('cpf')" class="help is-danger">{{ errors.first('cpf') }}</span>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <v-select
                            class="select-companie"
                            @input="selectedCompanie(companies.selected)"
                            placeholder="Venda para empresa"
                            v-model="form.empresa"
                            :options="companies.list"
                            :reduce="opt => opt"
                            :disabled='disableField'
                            :clearable="false"
                            :get-option-label="(option) => option.name">
                        </v-select>
                    </b-col>
                    </b-form-row>
                        <b-form-row v-if="!form.partner">
                            <b-col>
                                <b-form-group id="namegroup" label-for="name">
                                    <b-form-input id="name"
                                                :disabled='disableField'
                                                type="text"
                                                v-model="form.name"
                                                maxlength="50"
                                                placeholder="Nome">
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                    </b-form-row>   
                <b-form-row v-if="!form.partner">
                    <b-col>
                        <b-form-group id="rggroup"
                                        label-for="rg">
                            <b-form-input id="rg"
                                        :disabled='disableField'
                                        type="text"
                                        v-model="form.rg"
                                        v-mask="['###############']"
                                        maxlength="15"
                                        placeholder="RG">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="cellphonegroup" label-for="cellphone">
                            <b-input-group>
                                <b-form-input
                                    id="ddd"
                                    :disabled='disableField'
                                    class="col-4"
                                    type="text"
                                    v-model="form.ddd_cell"
                                    v-mask="['##']"
                                    maxlength="2"
                                    placeholder="DDD">
                                </b-form-input>
                                <b-form-input
                                    id="cellphone"
                                    :disabled='disableField'
                                    type="text"
                                    v-model="form.cellphone"
                                    v-mask="['#########']"
                                    maxlength="9"
                                    placeholder="Whatsapp">
                                </b-form-input>                              
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row v-if="!form.partner">
                    <b-col>
                        <b-form-group id="emailgroup"
                                        label-for="email">
                            <b-form-input id="email"
                                        :disabled='disableField'
                                        type="email"
                                        v-model="form.email"
                                        maxlength="150"
                                        placeholder="E-mail">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="phonegroup"
                                        label-for="telefone">
                            <b-input-group>
                                <b-form-input id="ddd"
                                            :disabled='disableField'
                                            type="text"
                                            v-model="form.ddd"
                                            v-mask="['##']"
                                            maxlength="2"
                                            placeholder="DDD">
                                </b-form-input>
                                <b-form-input id="telefone"
                                            :disabled='disableField'
                                            type="text"
                                            v-model="form.phone"
                                            v-mask="['#########']"
                                            maxlength="20"
                                            placeholder="Telefone">
                                </b-form-input>
                                <b-form-input id="ramal"
                                            :disabled='disableField'
                                            type="text"
                                            v-model="form.ramal"
                                            v-mask="['####']"
                                            maxlength="4"
                                            placeholder="Ramal">
                                </b-form-input>                                
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-button type="submit" variant="primary">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                            Salvando...
                            </template>
                        </v-wait>
                        <span v-if="!processing">Salvar</span>
                    </b-button>
                    <b-button type="button" variant="secondary" @click="cancel">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                            Aguarde...
                            </template>
                        </v-wait>
                        <span v-if="!processing">Fechar</span>
                    </b-button>
                    <b-button type="button" variant="warning" @click="form.partner=true;" v-if="!form.partner">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                            Salvando...
                            </template>
                        </v-wait>
                        <span v-if="!processing">Reserva para parceiro</span>
                    </b-button>
                    <b-button type="button" variant="warning" @click="form.partner=false;" v-if="form.partner">
                        <v-wait for="inprocess">
                            <template slot="waiting">
                            Salvando...
                            </template>
                        </v-wait>
                        <span v-if="!processing">Reserva para cliente</span>
                    </b-button>
                </b-form-row>
            </b-form>
        </b-container>
</template>

<script>
import Vue from 'vue';
import VueResource from "vue-resource";
import VeeValidate from 'vee-validate';
import config from '@/config';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { func } from '@/functions';
import { funcOperation } from '../../components/ticketoffice/services/functions';
import { clientService } from "../../components/ticketoffice/services/client";
import { quotapartnerService } from "../../components/common/services/quotapartner";
import { dashboardService } from "../../components/common/services/dashboard";

import {mask} from 'vue-the-mask';

Vue.use(VeeValidate, {
    fieldsBagName: 'vvFields',
});
Vue.component('v-select', vSelect)

export default {
    name: 'client',
    mixins: [func, funcOperation],
    directives: {mask},
    props: ['needCPF'   , 'needRG', 'needPhone', 'needName', 'needCardBin', 'showCardBin', 'code'],
    data () {
        return {
            isClient: true,
            isClientAdd: true,
            processing: false,
            foundClient: false,
            loading: false,
            result: null,
            companies: {
                selected: '',
                list: []
            },
            selects: {
                partners: [],
            },
            form: {
                partner: false,
                id_quotapartner: '',
                code: '',
                cpf: '',
                cnpj: '',
                rg: '',
                ddd: '',
                ddd_cell: '',
                phone: '',
                cellphone: '',
                ramal: '',
                name: '',
                email: '',
                bincard: '',
                empresa: '',
            }
        }
    },
    created() {
        this.load();
    },
    async mounted () {
        const listOfCompanies = await dashboardService.getCompanies(this.getLoggedId())
        if (listOfCompanies && listOfCompanies.empresas) {
            this.companies.list = listOfCompanies.empresas.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    computed: {
        disableField () {
            return (this.code || this.foundClient)
        }
    },
    methods: {
        selectedCompanie (companie) {
            this.form.cnpj = companie.cnpj
        },
        selQuotaPartner(item) {
            Vue.nextTick().then(response => {
                let index = this.selects.partners.map(function (e) {
                    return e.id;
                }).indexOf(item);
                if (index != -1) {
                    this.form.name = this.selects.partners[index].name;
                }
            });
        },
        cancel() {
            if (this.$route.fullPath == "/ticketoffice/operation/reservation/seat") 
            {
                this.$parent.getHeader().cancelingReservationProcess();
                this.$router.push("/ticketoffice/");
            }
            else {
                this.$parent.getCashRegister().hideClient();
            }
        },
        onSubmit (evt) {
            evt.preventDefault();
            this.save();
        },
        load() {
            if (this.$props["code"] != "" && this.$props["code"] != null) {
                this.form.code = this.$props["code"];
                this.get(false);
            }
            this.populateQuotapartners();
        },
        populateQuotapartners() {
            this.processing = true;
            this.showWaitAboveAll();

            quotapartnerService.select().then(
                    response => {
                        this.hideWaitAboveAll();
                        this.processing = false;
                        this.selects.partners = response;
                },
                error => {
                    this.processing = false;
                    this.hideWaitAboveAll();
                    this.toastError("Falha na execução.");        
                }
            );
        },        
        get(check, type) {
            if (this.processing) return;
            if (check && type === 'cpf') {
                if (this.form.cpf === "") {
                    this.toastError("CPF é obrigatório.");
                    this.form = {
                        partner: false,
                        id_quotapartner: '',
                        code: '',
                        cpf: '',
                        cnpj: '',
                        rg: '',
                        ddd: '',
                        ddd_cell: '',
                        phone: '',
                        cellphone: '',
                        ramal: '',
                        name: '',
                        email: '',
                        bincard: '',
                        empresa: '',
                    }
                    this.companies.selected = ''
                    return;
                }
            }
            if (check && type === 'cnpj') {
                if (this.form.cnpj === '') {
                    this.toastError("CNPJ é obrigatório.");
                    this.form = {
                        partner: false,
                        id_quotapartner: '',
                        code: '',
                        cpf: '',
                        cnpj: '',
                        rg: '',
                        ddd: '',
                        ddd_cell: '',
                        phone: '',
                        cellphone: '',
                        ramal: '',
                        name: '',
                        email: '',
                        bincard: '',
                    }
                    this.companies.selected = ''
                    return;
                }
            }
            this.processing = true;
            this.showWaitAboveAll();

            const searchType = type === 'cpf' ? this.form.cpf : this.form.cnpj

            clientService.get(this.get_id_base(), searchType, this.form.code).then(
                    response => {
                        this.hideWaitAboveAll();
                        this.processing = false;

                        if (response.length == 0 && type === 'cpf') {
                            this.toastError("Nenhum cliente encontrado com o CPF informado.");
                            this.foundClient = false;
                            const previousCPF = this.form.cpf
                            this.form = {
                                partner: false,
                                id_quotapartner: '',
                                code: '',
                                cpf: previousCPF,
                                cnpj: '',
                                rg: '',
                                ddd: '',
                                ddd_cell: '',
                                phone: '',
                                cellphone: '',
                                ramal: '',
                                name: '',
                                email: '',
                                bincard: '',
                                empresa: '',
                            }
                            this.companies.selected = ''
                            return;
                        }

                        if (response.length == 0 && type === 'cnpj') {
                            this.toastError("Nenhum cliente encontrado com o CNPJ informado.");
                            this.form = {
                                partner: false,
                                id_quotapartner: '',
                                code: '',
                                cpf: '',
                                cnpj: '',
                                rg: '',
                                ddd: '',
                                ddd_cell: '',
                                phone: '',
                                cellphone: '',
                                ramal: '',
                                name: '',
                                email: '',
                                bincard: '',
                            }
                            this.companies.selected = ''
                            return;
                        }

                        if (this.validateJSON(response)) {
                            const companyObj = this.companies.list ? this.companies.list.find(company => company.cnpj === response.cnpj) : ''
                            this.form.cpf = response.cpfclean;
                            this.form.rg = response.RG;
                            this.form.ddd = response.DDD;
                            this.form.phone = response.Telefone;
                            this.form.ramal = response.Ramal;
                            this.form.name = response.Nome;
                            this.form.email = response.EMail;
                            this.form.bincard = response.CardBin;
                            
                            this.form.ddd_cell = response.cellddd;
                            this.form.cellphone = response.cellphone;
                            this.form.empresa = companyObj || ''
                            this.form.cnpj = response.cnpj;
                            this.foundClient = true;
                        }
                },
                error => {
                    this.processing = false;
                    this.hideWaitAboveAll();
                    this.toastError("Falha na execução.");        
                }
            );
        },
        save() {
            if (this.processing) return;

            if ((this.$props["needName"] == true || this.$props["needName"]=="true") && this.form.name == "") {
                this.toastError("Nome é obrigatório.");
                return;
            }

            if (this.form.partner) {

            }
            else {
                if ((this.$props["needCPF"] == true || this.$props["needCPF"] == "true") && this.form.cpf == "") {
                    this.toastError("CPF é obrigatório.");
                    return;
                }
                if ((this.$props["needRG"] == true || this.$props["needRG"] == "true") && this.form.rg == "") {
                    this.toastError("RG é obrigatório.");
                    return;
                }
                if ((this.$props["needPhone"] == true || this.$props["needPhone"] == "true") && (this.form.phone == "" || this.form.ddd == "")) {
                    this.toastError("Telefone é obrigatório.");
                    return;
                }
                if ((this.$props["needCardBin"] == true || this.$props["needCardBin"] == "true") && this.form.bincard == "") {
                    this.toastError("Bin do Cartão é obrigatório.");
                    return;
                }

            }


            this.processing = true;
            this.showWaitAboveAll();

            let makeCode = false;

            if (this.$route.fullPath == "/ticketoffice/operation/reservation/seat") 
                makeCode = true;

            
            clientService.add(this.get_id_base(), this.getLoggedId(), this.form.cpf, this.form.rg, this.form.name, this.form.email, this.form.ddd, this.form.phone, this.form.ramal, this.form.bincard, makeCode, this.form.partner, this.form.id_quotapartner, this.form.cellphone, this.form.ddd_cell, this.form.cnpj, this.form.empresa).then(
                    response => {
                        this.hideWaitAboveAll();
                        this.processing = false;
                        if (this.validateJSON(response)) {
                            if (response.added == "1" || response.add == 1) {
                                this.toastSuccess("Cliente cadastrado com sucesso.");
                            }
                            else {
                                this.toastSuccess("Cliente escolhido com sucesso.");
                            }
                            this.result = response.codigo;

                            if (this.$route.fullPath == "/ticketoffice/operation/reservation/seat") 
                            {
                                this.$parent.codCliente = response.codigo;
                                this.$parent.codReserva = response.codReserva;
                                this.$parent.hasCPF = response.hasCPF;
                                Vue.nextTick().then(d => {
                                    this.$parent.hideClientWhenForced();
                                });
                            }
                            else {
                                this.$parent.getCashRegister().form.clientCode = response.codigo;
                                this.$parent.getCashRegister().form.hasCPF = response.hasCPF;
                                this.$parent.getCashRegister().form.cardBin = this.form.bincard;
                                Vue.nextTick().then(response => {
                                    this.$parent.getCashRegister().hideClient();
                                    this.$parent.getCashRegister().checkIfCanSell();
                                });
                            }
                        }
                },
                error => {
                    this.processing = false;
                    this.hideWaitAboveAll();
                    this.toastError("Falha na execução.");        
                }
            );

        }
    }
}
</script>

<style lang="scss">
.select-companie {
    .vs__dropdown-toggle {
        height: 2.375rem;
        background: #FFFFFF;
        border: 1px solid #0028642c;
    }
    .vs__search::placeholder {
        color: #adb5bd;
    }
}
</style>
